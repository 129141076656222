import React from 'react'
import Container from 'react-bootstrap/Container'

import CardGrid from '../CardGrid'

// import './style.css'

function Portfolio() {
    const portfolioObject = [
        {
            title: "SLA Reporting Tool",
            // image: "https://picsum.photos/id/237/200/200",
            text: "This application is intended to provide a report using data from external sources which is cached, compiled, and output as a summary of the data.",
            linkPath: "https://github.com/michaelscales88/python-reporting-app",
            gallery: true
        },
        {
            title: "Falcon Gallery",
            text: "Flask photo gallery application",
            linkPath: "https://github.com/michaelscales88/falcon_gallery",
            gallery: true
        },
        {
            title: "Sensor Management",
            text: "A little web project to hopefully manage some sensors and perform some tasks.",
            linkPath: "https://github.com/michaelscales88/mikes_revel_app",
            gallery: true
        },

    ]
    return (
        <Container className="section-container" id="portfolio">
            <h1 className="text-start">My Portfolio</h1>
            <div className="section-content">
                <CardGrid data={portfolioObject} />
            </div>
        </Container>
    )
}

export default Portfolio

