import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import CardGrid from '../CardGrid'
import './style.css'

function Team() {

    return (
        <Container className="section-container" id="about">
            <h1 className="text-end">About Me</h1>
            <div className="section-content">
                <p>
                    I’m generally curious and I like to experiment. I started in the US Navy nuclear program working on submarines. After serving, I cross trained to software development and I couldn’t be happier. I love solving problems, so it feels like a great fit.
                </p>
                <p>
                    I really enjoy full stack development with an emphasis on working in Linux/*nix environments. I’m experienced in mobile and web stacks including creating+maintaining large team infrastructure and tooling.
                </p>
                <p>
                    I’m interested in all things engineering, containerizing everything, building cool dev tools, and space. Space exploration is really interesting to me and I hope to work in the industry some day. 🚀 🌎
                </p>
            </div>
        </Container>
    )
}

export default Team