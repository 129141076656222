import React from "react"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import { Link } from 'react-router-dom';

import { Navigation } from '../../Components/Navigation'
import img from '../../assets/images/404.gif'
import './style.css'

function NotFound() {
    return (
        <div className="viewPage">
            <Navigation />
            <Container fluid className="notFound">
                <Row lg={2} className="imageCenter" >
                    <Image src={img} fluid />
                </Row>
                <Row>
                    <Col className="text-center">
                        <Link to="/">
                            <Button variant="danger">return</Button>
                        </Link>
                    </Col>
                </Row >
            </Container>
        </div>

    )
}

export default NotFound