import React from "react"
import Row from 'react-bootstrap/Row'
import Image from 'react-bootstrap/Image'
import img from '../../assets/images/comingsoon.png'
import './style.css'

function ComingSoon() {
    return (
        <div className="comingSoon">
            <Row lg={2} md={2} className="imageCenter">
                <Image fluid src={img} />
            </Row>
        </div>

    )
}

export default ComingSoon