import React from 'react'
import Scroll, { Link } from 'react-scroll'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Image from 'react-bootstrap/Image'

import { ContactSocialIcons } from '../Contact'
import './style.css'

export function HomeNavigation() {
    const scroll = Scroll.animateScroll

    return (
        <Navbar className="navBackgroundColor" variant="dark" expand="md" sticky="top">
            <Navbar.Brand href='#'><Image src="../images/logoimg.png" height={50} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                <Nav>
                    <Link to="about" offset={-80} className="Link">About</Link>
                    <Link to="portfolio" offset={-80} className="Link">Portfolio</Link>
                    <Link to="resume" offset={-80} className="Link">Resume</Link>
                    {/* <Nav.Link href="/contact" className="Link" style={{ padding: '0' }}>Contact</Nav.Link> */}
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
}

export function Navigation() {
    return (
        <Navbar className="navBackgroundColor" variant="dark" expand="md" sticky="top">
            <Navbar.Brand href='#'><Image src="../images/logoimg.png" height={50} /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                <Nav>
                    <Nav.Link href="/" offset={-80} >Home</Nav.Link>
                    <Nav.Link href="/contact">Contact</Nav.Link>

                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
}
