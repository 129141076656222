import Container from 'react-bootstrap/Container'
import { Parallax } from 'react-parallax'
import { Element } from 'react-scroll'
import { HomeNavigation } from '../../Components/Navigation'
import Banner from '../../Components/Banner'
import Team from '../../Components/Team'
import Portfolio from '../../Components/Portfolio'
import Resume from '../../Components/Resume'
import Footer from '../../Components/Footer'

import Background from '../../assets/images/bkg.jpg'
import Parallax3 from "../../assets/images/parallax3.jpg";
import './style.css'

function Landing() {
    const bannerObject =
    {
        header: "Hey, I'm Michael",
        text: "I like dogs",
        image: "./images/kele.png",
    }

    return (
        <div className="viewPage">
            <HomeNavigation />
            <Parallax
                bgImage={Background}
                strength={550}
            >
                <Element name="header" className="Element">
                    <Banner data={bannerObject} />
                </Element>
            </Parallax>

            <Parallax
                bgImage={Parallax3}
                strength={550}>
                <Container className="pageContent">
                    <Element name="about" className="Element"><Team /></Element>
                    <Element name="portfolio" className="Element"><Portfolio /></Element>
                    {/* <Element name="resume" className="Element"><Resume /></Element> */}
                </Container>
                <Footer />
            </Parallax>
        </div>
    )
}

export default Landing